'use strict'

const Obstruct = require('./obstruct')
const optional = require('./optional')
const array = require('./array')
const parent = require('./parent')

Obstruct.optional = optional
Obstruct.array = array
Obstruct.parent = parent

module.exports = Obstruct
