'use strict'

const load = require('load-script')
const window = require('global/window')
const extend = require('xtend')
const dezalgo = require('dezalgo')
const Listeners = require('./ear')
const extendQuery = require('query-extend')
const cuid = require('cuid')

module.exports = loadGlobal

const listeners = {}

function loadGlobal (options, callback) {
  if (!options) throw new Error('options required')
  if (!options.url) throw new Error('url required')
  if (!options.global) throw new Error('global required')
  if (!callback) throw new Error('callback required')

  options = extend(options)
  callback = dezalgo(callback)

  if (getGlobal(options)) {
    return callback(null, getGlobal(options))
  }

  callback = cache(options, callback)
  if (!callback) return

  if (options.jsonp) {
    const id = jsonpCallback(options, callback)
    options.url = extendQuery(options.url, { callback: id })
  }

  load(options.url, options, function (err) {
    if (err) return callback(err)
    if (!options.jsonp) {
      const library = getGlobal(options)
      if (!library) return callback(new Error('expected: `window.' + options.global + '`, actual: `' + library + '`'))
      callback(null, library)
    }
  })
}

function cache (options, callback) {
  if (!get()) {
    set(Listeners())
    get().add(callback)
    return function onComplete (err, lib) {
      get()(err, lib)
      set(Listeners())
    }
  }

  get().add(callback)
  return undefined

  function get () {
    return listeners[options.global]
  }

  function set (value) {
    listeners[options.global] = value
  }
}

function getGlobal (options) {
  return window[options.global]
}

function jsonpCallback (options, callback) {
  const id = cuid()
  window[id] = function jsonpCallback () {
    callback(null, getGlobal(options))
    delete window[id]
  }
  return id
}
