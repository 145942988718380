'use strict'

const load = require('@eaze/load-script-global')
const Obstruct = require('@eaze/obstruction')
const pipe = require('value-pipe')
const qs = require('query-string')
const setQuery = require('url-set-query')
const partial = require('ap').partial

module.exports = loadMaps

const base = 'https://maps.googleapis.com/maps/api/js'
const format = Obstruct({
  key: true,
  libraries: Obstruct.optional(concat),
  client: true,
  v: 'version',
  channel: true,
  language: true,
  region: true
})

function loadMaps (options, callback) {
  if (typeof options === 'function') {
    callback = options
    options = {}
  }

  load({
    url: pipe(format, qs.stringify, partial(setQuery, base))(options),
    global: 'google',
    jsonp: true
  }, callback)
}

function concat (list) {
  return list.join(',')
}
