'use strict'

const dot = require('dot-prop')
const toArray = require('to-array')
const last = require('array-last')
const dezalgo = require('dezalgo')
const all = require('call-all-fns')

module.exports = Lazy

function Lazy (methods, load) {
  if (!Array.isArray(methods)) {
    throw new Error('methods are required')
  }

  if (typeof load !== 'function') {
    throw new Error('load fn is required')
  }

  let api = null
  let error = null
  let queue = []

  load(function (err, lib) {
    error = err
    api = lib
    all(queue)(err, lib)
    queue = null
  })

  return methods.reduce(function (lazy, method) {
    dot.set(lazy, method, Deferred(method))
    return lazy
  }, {})

  function Deferred (method) {
    return function deferred () {
      const args = arguments
      onReady(function (err, api) {
        if (!err) return dot.get(api, method).apply(null, args)
        const callback = last(toArray(args))
        if (typeof callback === 'function') {
          return callback(err)
        }
      })
    }
  }

  function onReady (callback) {
    callback = dezalgo(callback)

    if (api || error) return callback(error, api)
    queue.push(callback)
  }
}
