'use strict'

const Lazy = require('@eaze/lazy-async')
const load = require('@eaze/load-script-global')
const partial = require('ap').partial
const methods = require('./methods.json')

module.exports = Segment

function Segment (options) {
  if (!options.key) {
    throw new Error('Segment key is required')
  }

  const url = options.url
    ? options.url
    : 'https://cdn.segment.com/analytics.js/v1/' + options.key + '/analytics.min.js'

  return Lazy(methods, partial(load, {
    url,
    global: 'analytics'
  }))
}
