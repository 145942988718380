// https://github.com/bendrucker/ear/blob/master/index.js
'use strict'

const assertFn = require('./assert-function')

module.exports = Ear

function Ear () {
  const callbacks = []

  function listeners () {
    const args = arguments
    let i = 0
    const length = callbacks.length
    for (; i < length; i++) {
      const callback = callbacks[i]
      callback.apply(null, args)
    }
  }

  listeners.add = function (listener) {
    assertFn(listener)
    callbacks.push(listener)
    return function remove () {
      let i = 0
      const length = callbacks.length
      for (; i < length; i++) {
        if (callbacks[i] === listener) {
          callbacks.splice(i, 1)
          return
        }
      }
    }
  }

  return listeners
}
